@mixin mobile {
  @media (max-width: 750px) {
    @content;
  }
}

@font-face {
  font-family: SuisseIntl;
  src:  url('./font/SuisseIntl-Regular-WebS.woff2') format('woff2'),
        url('./font/SuisseIntl-Regular-WebS.woff') format('woff');
  font-weight: 400;
  font-display: swap;
} 

@font-face {
  font-family: SuisseIntl;
  src:  url('./font/SuisseIntl-Medium-WebS.woff2') format('woff2'),
        url('./font/SuisseIntl-Medium-WebS.woff') format('woff');
  font-weight: 500;
  font-display: swap;
} 

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}



/* Remove list styles (bullets/numbers) */
ol, ul, menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  display: block;
  max-width: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}


/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly. */
:where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
}

:root {
  font-family: SuisseIntl, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 0.03em;

  --c-surface: #fff;
}

html {
  background: var(--c7);
  color: var(--c9);
  font-size: calc(17.5% + 0.6vw); // 10px / 1200px
}

::selection {
  background: var(--c7);
  color: var(--c9);
}

body {
  font-size: 2.4rem; // 24px
}

h1, h2, h3, h4 {
  font-family: SuisseIntl, sans-serif;
  font-weight: 500;
}

h1 {
  font-size: 6.4rem;
}

.layout {
  margin: 0 var(--logo-width) 0 0;
  //overflow: hidden;

  &__visual {
    position: relative;
    height: 100vh;
    margin: 0 auto;
    transform: translate(0,0);

    background: var(--c0);
  }

  &__inner {
    padding: calc(var(--size-4) * 1rem);
  }
}


.inverted {
  background: var(--c9);
  color: var(--c0);
}

.sidebyside {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__left {
    flex: 0 0 50%;
  }
  &__right {
    flex: 0 0 50%;
  }

  @include mobile {
    flex-direction: column;
    &__left {
      flex: 0 0 100%;
      order: 2;
    }
    &__right {
      flex: 0 0 100%;
      order: 1;
    }
  }
}

.entries {
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: scroll;
}


.entry-detail__close {
  display: none;
  position: absolute;
  top: 4rem;
  right: 4rem;
  font-size: 3em;
  cursor: pointer;
}

.entry-detail__body {
  letter-spacing: 0.045em;
  font-weight: 500;

  a {
    color: var(--c5);
  }
  @include mobile {
    letter-spacing: 0.02em;
    margin-right: -15%;
  }
}

@include mobile {
  .entry-detail {
    position: fixed;
    inset: 0;
    right: var(--logo-width);
    overflow-y: scroll;
  }
  .entry-detail__close {
    display: block;
  }
}

.placeholder {
  z-index: -1;
  position: relative;
  width: 100%;
  aspect-ratio: 1;

  background: var(--c0);
  &__inner {
    position: absolute;
    inset: calc(var(--size-4) * 1rem);
  }
}

.warn {
  /*z-index: 2;
  position: sticky;
  top: 0;*/
  background: var(--c2);
  color: var(--c6);
  padding: calc(var(--size-3) * 1rem);
  letter-spacing: 0.03em;

  h1, h2 {
    font-weight: 400;
  }

  @include mobile {
    font-size: 3.6rem;
  }
}

.article {
  padding: calc(var(--size-3) * 1rem);
  background: var(--c1);

  &--intro {
    h1, h2, p {
      font-size: 3.6rem;
      font-weight: 400;
      line-height: 1.3;
    }
    h1 + p, 
    p + p, 
    h2 + p, 
    p + h1, 
    p + h2, 
    p + h3, 
    p + h4 {
      margin-top: 1em;
    }
    *:last-child {
      margin-bottom: 2em;
    }
  }
  &--engage {
    p {
      font-size: 2.4rem;
      letter-spacing: 0.03em;
      
      @include mobile {
        font-size: 3rem;
        letter-spacing: 0.04em;
      }
    }
  }
}

.entry {
  display: block;
  background: var(--c7);
  &:hover .entry__inner,
  &:focus .entry__inner {
    border-radius: 10rem;
  }
}

.entry__inner {
  padding: calc(var(--size-4) * 1rem) calc(var(--size-3) * 1rem);
  background: var(--c9);
}

.entry__title {
  display: block;
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.1;
  margin-top: 0.1em;
  min-height: calc(3.6rem * 2.2);
  letter-spacing: 0;
}

.entry__meta {
  font-size: 1em;
  font-weight: 500;
  display: flex;
}

.entry__category {
  text-transform: uppercase;
  flex: 0 0 45%;
  letter-spacing: 0.04em;
  font-size: 1.6rem;

  @include mobile {
    font-size: 2.5rem;
  }
}

.entry__data {
  flex: 0 0 60%;
}

.entry__date,
.entry__time {
  display: block;
  font-size: 1.6rem;


  @include mobile {
    font-size: 3rem;
  }
}

.entry__time {
  display: none;
}

.entry-detail__image {
  border-bottom-right-radius: 10rem;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    filter: grayscale(100%);
  }
}

.entry-detail {
  display: none;
  color: var(--c1);

  &.is-visible {
    display: block;
  }
}

.entry-detail__tagline {
  font-weight: 500;
  letter-spacing: 0.03em;
}

.entry-detail__facts {
  margin-top: calc(var(--size-5) * 1rem);

  @include mobile {
    font-size: 3rem;
    letter-spacing: 0.04em;
    margin-right: -15%;
  }
}

.entry-detail__content {
  position: relative;
  background: var(--c9);
  background: var(--c-surface);
  border-radius: 10rem;
  border-bottom-right-radius: 0;
  padding: 4rem;
  padding-right: calc(var(--size-6) * 1rem);
  font-size: 1.6rem;
  line-height: 1.25;

  h1 {
    font-size: 5.5rem;
    font-weight: 400;
    line-height: 1.06;
    margin-bottom: 1em;
  }
  
  p {
    margin-top: 1em;
  }

  @include mobile {
    font-size: 3.6rem;
  }
}

a {
  cursor: pointer;
}

.button {
  padding: 1em;
  border: 2px solid currentColor;
  border-radius: 2em;
  line-height: .8;
  font-weight: 400;

  &:focus {
    background-color: var(--c9);
    color: var(--c0);
    box-shadow: inset 0 0 0 2px currentColor;
  }
}

:root {
  // fontsize 6.8vh * line-height (.8) + vertical-padding (.3em + 25em) + border (3px * 2)
  // 5.44 + 2.04 + 1.7 = 8.8vh
  --logo-width: calc(9.05vh + (3px * 2));

  @include mobile {
    --logo-width: calc(7.05vh + (3px * 2));
  }
}

.logo {
  --s-gap: 1.6em;
  --s-overlap-ratio: -0.86;
  box-shadow: inset 0 0 0 3px var(--c0);
  border-radius: 2em;
  animation: grow 2s paused cubic-bezier(.7, .3, .3, 1) forwards;
  animation-delay: calc(var(--relativeScroll) * -2s);
  letter-spacing: 0;
  .is-safari & {
    animation: none;
  }
}
  
.logo b {
  box-shadow: inset 0 0 0 3px var(--c0);
  border-radius: 2em;
  line-height: .8;
  padding: .4em  0;
  color: var(--c0);
  width: var(--logo-width);
  display: flex;
  align-items: center;
  position: relative;

  will-change: font-size, padding, width;
  animation: shrink 2s paused cubic-bezier(.7, .3, .3, 1) forwards;
  animation-delay: calc(var(--relativeScroll) * -2s);

  &:nth-child(1) {
    padding-bottom: var(--s-gap);
    margin-bottom: calc(var(--s-overlap-ratio) * var(--s-gap));
  }
  &:nth-child(2) {
    padding-bottom: var(--s-gap);
    padding-top: var(--s-gap);
    margin-bottom: calc(var(--s-overlap-ratio) * var(--s-gap));
    animation: squash 2s paused cubic-bezier(.7, .3, .3, 1) forwards;
    animation-delay: calc(var(--relativeScroll) * -2s);
  }
  &:nth-child(3) {
    padding-top: var(--s-gap);
  }

  .is-safari & {
    animation: none !important;
  }
}

@keyframes grow {
  100% {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

@keyframes squash {
  40% {
    box-shadow: inset 0 0 0 3px transparent;
  }
  100% {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 2.4rem;
    box-shadow: inset 0 0 0 3px transparent;
  }
}

@keyframes shrink {
  40% {
    box-shadow: inset 0 0 0 3px transparent;
  }
  100% {
    font-size: 2.4rem;
    box-shadow: inset 0 0 0 3px transparent;
  }
}

.navigation {
  writing-mode: vertical-rl;
  text-orientation: mixed;  
  font-weight: 500;
  display: flex;
  position: absolute;
  top: calc(100vh + 28rem);

  right: 0;
  font-size: 2.4rem;
  white-space: nowrap;
  width: var(--logo-width);

  @include mobile {
    font-size: 3.6rem;
  }

  .is-safari & {
    top: 100vh;
    @include mobile {
      top: calc(100vh + 100vw - var(--logo-width));
    }
  }

  &__item {
    border: 3px solid var(--c0);
    border-radius: 9999px;
    line-height: .8;
    padding: .4em .3em .4em .25em;
    color: var(--c1);
    display: flex;
    align-items: center;  
    padding: 1em 0;
    font-weight: 400; 
  }

  &__logo {
    display: none;
  }

  input {
    opacity: 0;
    position: absolute;
    top: 1em;
    right: 50%;
    transform-origin: 100% 0;
    transform: rotate(90deg) translate(100%, -50%);
    width: 13em;
    
    + span {
      pointer-events: none;
    }

    .is-firefox & {
      text-orientation: sideways;
      height: 13em;
      width: auto;
      transform: translateX(50%);
      margin: 0;
      padding: 0;
    }
  }

  button {
    display: block;
    font-size: 1.5em;
    margin-top: var(--s-gap);
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
  }
}

a.navigation__item,
.navigation__input {
  transition: background-color .2s cubic-bezier(.7, .3, .3, 1), box-shadow .3s cubic-bezier(.7, .3, .3, 1), border-color .3s cubic-bezier(.7, .3, .3, 1);
  box-shadow: inset 0 0 0 0 var(--c0);
  &:hover,
  &[aria-expanded=true] {
    background: var(--c9);
  }
}


.is-valid button {
  opacity: 1;
  pointer-events: auto;
}

::-webkit-input-placeholder {
  color: var(--c9);
}

::placeholder {
  color: var(--c9);
}

.navigation__input {
  position: relative;
}
.navigation__input input:focus,
.navigation__input input:not(:placeholder-shown) {
  opacity: 1;
}
.navigation__input input:focus + span,
.navigation__input input:not(:placeholder-shown) + span {
  opacity: 0;
}

.logo--vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-weight: 400;
  display: flex;
}

.logo--main {
  position: fixed;
  right: 0;
  top: 0;
  color: var(--c0);
  font-size: 5.8rem;
  font-size: 6.8vh;

  @include mobile {
    font-size: 4.8rem;
    font-size: 5.8vh;
  }

  .is-safari & {
    position: absolute;
    top: 0 !important;
  }
}

.is-intro-done .logo--main {
  position: absolute;
  top: 100vh;

  .is-safari & {
    top: 0;
  }
}

.visual {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70vmin;
  height: 70vmin;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.clock {
  animation: 2s moveDown paused forwards;
  animation-delay: calc(var(--relativeScroll) * -2s);
  top: 50%; left: 50%;
  transform: translate(-50%, -50%) scale(.999);
  transform-origin: 0 0;
}

@keyframes moveDown {
  100% {
    transform: translate(-50%, -50%) translate(calc(var(--targetXDiff) * 1px), calc(var(--targetYDiff) * 1px)) scale(var(--targetScale));
  }
}

.clock {
  position: absolute;

  width: 100%;
  height: 100%;

  &__part {
    --z: 1px;
    opacity: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, var(--z)) rotate(calc(1deg * var(--deg))) scale(1);
    transition: 1s transform linear;
    background: radial-gradient(closest-side, var(--gs));
    background: conic-gradient(var(--c-gradient));

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: conic-gradient(var(--c-gradient));
      border-radius: 50%;
      width: 100%;
      height: 100%;
      filter: blur(10vmin);
      mix-blend-mode: overlay;
      display: none;
    }
  }

  &__s {
    --deg: var(--ah);
    --z: 3px;
    
    width: 44%;
    height: 44%;
  }

  &__m {
    --deg: var(--am);
    --z: 2px;
    width: 72%;
    height: 72%;
  }

  &__h {
    --deg: var(--as);
    --z: 1px;
    width: 100%;
    height: 100%;
  }
}

.footer {
  color: var(--c1);
  font-size: 1.6rem;

  @include mobile {
    font-size: 2.5rem;
  }
}

.footer__content {
  padding: calc(var(--size-3)  * 1rem);
}

.footer__impressum {
  padding: calc(var(--size-3)  * 1rem);

  h1, h2, h3 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  p {
    font-size: 1.6rem;
    margin: 1em 0;
  }

  @include mobile {
    h1,h2,h3 {
      font-size: 2.4rem;
    }
    p {
      font-size: 2.4rem;
    }
  }
}


.footer__list {
  display: flex;
  flex-wrap: wrap;

  li + li {
    margin-left: 0.5em;
    &::before {
      content: '•';
      margin-right: 0.5em;
    }
  }
}

