@font-face {
  font-family: SuisseIntl;
  src: url("SuisseIntl-Regular-WebS.100855bb.woff2") format("woff2"), url("SuisseIntl-Regular-WebS.9f1f8777.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: SuisseIntl;
  src: url("SuisseIntl-Medium-WebS.b7c2014f.woff2") format("woff2"), url("SuisseIntl-Medium-WebS.40a1ea34.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

:where(:not(html):not(iframe):not(canvas):not(img):not(svg):not(video):not(svg *):not(symbol *)) {
  all: unset;
  display: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

::-ms-input-placeholder {
  color: unset;
}

::placeholder {
  color: unset;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}

:root {
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: .03em;
  --c-surface: #fff;
  font-family: SuisseIntl, sans-serif;
  font-weight: 400;
}

html {
  background: var(--c7);
  color: var(--c9);
  font-size: calc(17.5% + .6vw);
}

::selection {
  background: var(--c7);
  color: var(--c9);
}

body {
  font-size: 2.4rem;
}

h1, h2, h3, h4 {
  font-family: SuisseIntl, sans-serif;
  font-weight: 500;
}

h1 {
  font-size: 6.4rem;
}

.layout {
  margin: 0 var(--logo-width) 0 0;
}

.layout__visual {
  height: 100vh;
  background: var(--c0);
  margin: 0 auto;
  position: relative;
  transform: translate(0);
}

.layout__inner {
  padding: calc(var(--size-4)  * 1rem);
}

.inverted {
  background: var(--c9);
  color: var(--c0);
}

.sidebyside {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.sidebyside__left, .sidebyside__right {
  flex: 0 0 50%;
}

@media (max-width: 750px) {
  .sidebyside {
    flex-direction: column;
  }

  .sidebyside__left {
    flex: 0 0 100%;
    order: 2;
  }

  .sidebyside__right {
    flex: 0 0 100%;
    order: 1;
  }
}

.entries {
  max-height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: scroll;
}

.entry-detail__close {
  cursor: pointer;
  font-size: 3em;
  display: none;
  position: absolute;
  top: 4rem;
  right: 4rem;
}

.entry-detail__body {
  letter-spacing: .045em;
  font-weight: 500;
}

.entry-detail__body a {
  color: var(--c5);
}

@media (max-width: 750px) {
  .entry-detail__body {
    letter-spacing: .02em;
    margin-right: -15%;
  }
}

@media (max-width: 750px) {
  .entry-detail {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    right: var(--logo-width);
    position: fixed;
    overflow-y: scroll;
  }

  .entry-detail__close {
    display: block;
  }
}

.placeholder {
  z-index: -1;
  width: 100%;
  aspect-ratio: 1;
  background: var(--c0);
  position: relative;
}

.placeholder__inner {
  inset: calc(var(--size-4)  * 1rem);
  position: absolute;
}

.warn {
  background: var(--c2);
  color: var(--c6);
  padding: calc(var(--size-3)  * 1rem);
  letter-spacing: .03em;
}

.warn h1, .warn h2 {
  font-weight: 400;
}

@media (max-width: 750px) {
  .warn {
    font-size: 3.6rem;
  }
}

.article {
  padding: calc(var(--size-3)  * 1rem);
  background: var(--c1);
}

.article--intro h1, .article--intro h2, .article--intro p {
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.3;
}

.article--intro h1 + p, .article--intro p + p, .article--intro h2 + p, .article--intro p + h1, .article--intro p + h2, .article--intro p + h3, .article--intro p + h4 {
  margin-top: 1em;
}

.article--intro :last-child {
  margin-bottom: 2em;
}

.article--engage p {
  letter-spacing: .03em;
  font-size: 2.4rem;
}

@media (max-width: 750px) {
  .article--engage p {
    letter-spacing: .04em;
    font-size: 3rem;
  }
}

.entry {
  background: var(--c7);
  display: block;
}

.entry:hover .entry__inner, .entry:focus .entry__inner {
  border-radius: 10rem;
}

.entry__inner {
  padding: calc(var(--size-4)  * 1rem) calc(var(--size-3)  * 1rem);
  background: var(--c9);
}

.entry__title {
  min-height: 7.92rem;
  letter-spacing: 0;
  margin-top: .1em;
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.1;
  display: block;
}

.entry__meta {
  font-size: 1em;
  font-weight: 500;
  display: flex;
}

.entry__category {
  text-transform: uppercase;
  letter-spacing: .04em;
  flex: 0 0 45%;
  font-size: 1.6rem;
}

@media (max-width: 750px) {
  .entry__category {
    font-size: 2.5rem;
  }
}

.entry__data {
  flex: 0 0 60%;
}

.entry__date, .entry__time {
  font-size: 1.6rem;
  display: block;
}

@media (max-width: 750px) {
  .entry__date, .entry__time {
    font-size: 3rem;
  }
}

.entry__time {
  display: none;
}

.entry-detail__image {
  border-bottom-right-radius: 10rem;
  overflow: hidden;
}

.entry-detail__image img {
  width: 100%;
  filter: grayscale();
  display: block;
}

.entry-detail {
  color: var(--c1);
  display: none;
}

.entry-detail.is-visible {
  display: block;
}

.entry-detail__tagline {
  letter-spacing: .03em;
  font-weight: 500;
}

.entry-detail__facts {
  margin-top: calc(var(--size-5)  * 1rem);
}

@media (max-width: 750px) {
  .entry-detail__facts {
    letter-spacing: .04em;
    margin-right: -15%;
    font-size: 3rem;
  }
}

.entry-detail__content {
  background: var(--c9);
  background: var(--c-surface);
  padding: 4rem;
  padding-right: calc(var(--size-6)  * 1rem);
  border-radius: 10rem 10rem 10rem 0;
  font-size: 1.6rem;
  line-height: 1.25;
  position: relative;
}

.entry-detail__content h1 {
  margin-bottom: 1em;
  font-size: 5.5rem;
  font-weight: 400;
  line-height: 1.06;
}

.entry-detail__content p {
  margin-top: 1em;
}

@media (max-width: 750px) {
  .entry-detail__content {
    font-size: 3.6rem;
  }
}

a {
  cursor: pointer;
}

.button {
  border: 2px solid;
  border-radius: 2em;
  padding: 1em;
  font-weight: 400;
  line-height: .8;
}

.button:focus {
  background-color: var(--c9);
  color: var(--c0);
  box-shadow: inset 0 0 0 2px;
}

:root {
  --logo-width: calc(9.05vh + (3px * 2) );
}

@media (max-width: 750px) {
  :root {
    --logo-width: calc(7.05vh + (3px * 2) );
  }
}

.logo {
  --s-gap: 1.6em;
  --s-overlap-ratio: -.86;
  box-shadow: inset 0 0 0 3px var(--c0);
  animation: grow 2s cubic-bezier(.7, .3, .3, 1) forwards paused;
  animation-delay: calc(var(--relativeScroll)  * -2s);
  letter-spacing: 0;
  border-radius: 2em;
}

.is-safari .logo {
  animation: none;
}

.logo b {
  box-shadow: inset 0 0 0 3px var(--c0);
  color: var(--c0);
  width: var(--logo-width);
  will-change: font-size, padding, width;
  animation: shrink 2s cubic-bezier(.7, .3, .3, 1) forwards paused;
  animation-delay: calc(var(--relativeScroll)  * -2s);
  border-radius: 2em;
  align-items: center;
  padding: .4em 0;
  line-height: .8;
  display: flex;
  position: relative;
}

.logo b:nth-child(1) {
  padding-bottom: var(--s-gap);
  margin-bottom: calc(var(--s-overlap-ratio)  * var(--s-gap) );
}

.logo b:nth-child(2) {
  padding-bottom: var(--s-gap);
  padding-top: var(--s-gap);
  margin-bottom: calc(var(--s-overlap-ratio)  * var(--s-gap) );
  animation: squash 2s cubic-bezier(.7, .3, .3, 1) forwards paused;
  animation-delay: calc(var(--relativeScroll)  * -2s);
}

.logo b:nth-child(3) {
  padding-top: var(--s-gap);
}

.is-safari .logo b {
  animation: none !important;
}

@keyframes grow {
  100% {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

@keyframes squash {
  40% {
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0);
  }

  100% {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 2.4rem;
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0);
  }
}

@keyframes shrink {
  40% {
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0);
  }

  100% {
    font-size: 2.4rem;
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0);
  }
}

.navigation {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  width: var(--logo-width);
  font-size: 2.4rem;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: calc(100vh + 28rem);
  right: 0;
}

@media (max-width: 750px) {
  .navigation {
    font-size: 3.6rem;
  }
}

.is-safari .navigation {
  top: 100vh;
}

@media (max-width: 750px) {
  .is-safari .navigation {
    top: calc(100vh + 100vw - var(--logo-width) );
  }
}

.navigation__item {
  border: 3px solid var(--c0);
  color: var(--c1);
  border-radius: 9999px;
  align-items: center;
  padding: 1em 0;
  font-weight: 400;
  line-height: .8;
  display: flex;
}

.navigation__logo {
  display: none;
}

.navigation input {
  opacity: 0;
  transform-origin: 100% 0;
  width: 13em;
  position: absolute;
  top: 1em;
  right: 50%;
  transform: rotate(90deg)translate(100%, -50%);
}

.navigation input + span {
  pointer-events: none;
}

.is-firefox .navigation input {
  text-orientation: sideways;
  height: 13em;
  width: auto;
  margin: 0;
  padding: 0;
  transform: translateX(50%);
}

.navigation button {
  margin-top: var(--s-gap);
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  font-size: 1.5em;
  display: block;
}

a.navigation__item, .navigation__input {
  box-shadow: inset 0 0 0 0 var(--c0);
  transition: background-color .2s cubic-bezier(.7, .3, .3, 1), box-shadow .3s cubic-bezier(.7, .3, .3, 1), border-color .3s cubic-bezier(.7, .3, .3, 1);
}

a.navigation__item:hover, a.navigation__item[aria-expanded="true"], .navigation__input:hover, .navigation__input[aria-expanded="true"] {
  background: var(--c9);
}

.is-valid button {
  opacity: 1;
  pointer-events: auto;
}

::-ms-input-placeholder {
  color: var(--c9);
}

::placeholder {
  color: var(--c9);
}

.navigation__input {
  position: relative;
}

.navigation__input input:focus, .navigation__input input:not(:placeholder-shown) {
  opacity: 1;
}

.navigation__input input:focus + span, .navigation__input input:not(:placeholder-shown) + span {
  opacity: 0;
}

.logo--vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-weight: 400;
  display: flex;
}

.logo--main {
  color: var(--c0);
  font-size: 6.8vh;
  position: fixed;
  top: 0;
  right: 0;
}

@media (max-width: 750px) {
  .logo--main {
    font-size: 5.8vh;
  }
}

.is-safari .logo--main {
  position: absolute;
  top: 0 !important;
}

.is-intro-done .logo--main {
  position: absolute;
  top: 100vh;
}

.is-safari .is-intro-done .logo--main {
  top: 0;
}

.visual {
  width: 70vmin;
  height: 70vmin;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clock {
  animation: moveDown 2s forwards paused;
  animation-delay: calc(var(--relativeScroll)  * -2s);
  transform-origin: 0 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.999);
}

@keyframes moveDown {
  100% {
    transform: translate(-50%, -50%) translate(calc(var(--targetXDiff)  * 1px), calc(var(--targetYDiff)  * 1px) ) scale(var(--targetScale) );
  }
}

.clock {
  width: 100%;
  height: 100%;
  position: absolute;
}

.clock__part {
  --z: 1px;
  opacity: 1;
  transform: translate3d(-50%, -50%, var(--z) ) rotate(calc(1deg * var(--deg) ) ) scale(1);
  background: radial-gradient(closest-side, var(--gs) );
  background: conic-gradient(var(--c-gradient) );
  border-radius: 50%;
  transition: transform 1s linear;
  position: absolute;
  top: 50%;
  left: 50%;
}

.clock__part:after {
  content: "";
  background: conic-gradient(var(--c-gradient) );
  width: 100%;
  height: 100%;
  filter: blur(10vmin);
  mix-blend-mode: overlay;
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clock__s {
  --deg: var(--ah);
  --z: 3px;
  width: 44%;
  height: 44%;
}

.clock__m {
  --deg: var(--am);
  --z: 2px;
  width: 72%;
  height: 72%;
}

.clock__h {
  --deg: var(--as);
  --z: 1px;
  width: 100%;
  height: 100%;
}

.footer {
  color: var(--c1);
  font-size: 1.6rem;
}

@media (max-width: 750px) {
  .footer {
    font-size: 2.5rem;
  }
}

.footer__content, .footer__impressum {
  padding: calc(var(--size-3)  * 1rem);
}

.footer__impressum h1, .footer__impressum h2, .footer__impressum h3 {
  font-size: 1.6rem;
  font-weight: 500;
}

.footer__impressum p {
  margin: 1em 0;
  font-size: 1.6rem;
}

@media (max-width: 750px) {
  .footer__impressum h1, .footer__impressum h2, .footer__impressum h3, .footer__impressum p {
    font-size: 2.4rem;
  }
}

.footer__list {
  flex-wrap: wrap;
  display: flex;
}

.footer__list li + li {
  margin-left: .5em;
}

.footer__list li + li:before {
  content: "•";
  margin-right: .5em;
}

/*# sourceMappingURL=index.b128238c.css.map */
